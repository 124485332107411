import { graphql } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import Data from "../components/Data";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import GetInTouch from "../components/GetInTouch";
import Hero from "../components/Hero";
import ImageWithContent from "../components/ImageWithContent";
import QuickStats from "../components/QuickStats";
import "../styles/index.scss";

const IndexPage = ({ data }) => {
  const homepageData = data.homepage.nodes[0].frontmatter;
  const footerData = data.footer.frontmatter;

  return (
    <div>
      <Helmet defer={false}>
        <title>{homepageData.title}</title>
        <meta name="description" content={homepageData.description} />
        <html lang="en" />
      </Helmet>
      <Hero headerData={homepageData.header} bannerData={homepageData.banner} />
      <Data dataSectionData={homepageData.data} />
      {homepageData.imageWithText.map((content, index) => (
        <ImageWithContent content={content?.imageWithTextItem} key={index} />
      ))}
      <QuickStats quickStatsData={homepageData.quickStats} />
      <Faq faqData={homepageData.faq} />
      <GetInTouch getInTouchData={homepageData.getInTouch} />
      <Footer footerData={footerData} />
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    homepage: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "content/homepage/" } }
    ) {
      nodes {
        frontmatter {
          title
          description
          header {
            buttonText
            buttonLink
          }
          banner {
            title
            description
            buttonText
            buttonLink
            loginText
            loginLink
            otherLoginText
            otherLoginLink
          }
          data {
            title
            description
            assetTitles
            unstructuredData
            silodDatabase
            card1 {
              title
              description
              points
            }
            card2 {
              title
              subtitle
              buttonText
              buttonLink
              description
            }
          }
          imageWithText {
            imageWithTextItem {
              title
              paragraph1
              points
              paragraph2
              imageSrc
              imageAlt
              imageAlignment
              imageWidth
              linkText
              linkUrl
            }
          }
          quickStats {
            number
            text
          }
          getInTouch {
            title
            buttonText
            buttonLink
          }
          faq {
            question
            answer
          }
        }
      }
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/footer.md/" }) {
      frontmatter {
        footerLine1
        mailTo
        telephone
      }
    }
  }
`;

import React from "react";
import * as styles from "./quickStats.module.scss";

const QuickStats = ({ quickStatsData }) => {
  return (
    <div className={styles.quickStatsContainer}>
      {quickStatsData.map((item, index) => {
        return (
          <div className={styles.quickStatsItem} key={index}>
            <h1 className={styles.quickStatsItemNumber}>{item.number}</h1>
            <p>{item.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default QuickStats;

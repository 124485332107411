import { Link } from "gatsby";
import React from "react";
import * as styles from "./imageWithContent.module.scss";

const ImageWithContent = ({ content }) => {
  return (
    <div
      className={`container ${
        content.imageAlignment?.toLowerCase() === "left"
          ? styles.parentWithLeftImage
          : styles.parentWithRightImage
      }`}
    >
      <div>
        <div
          className={`${styles.content} ${
            content.imageAlignment === "left"
              ? styles.contentRight
              : styles.contentLeft
          }`}
        >
          <h2>{content.title}</h2>
          {content.paragraph1 ? <p>{content.paragraph1}</p> : <></>}
          <ul>
            {content.points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
          {content.paragraph2 ? <p>{content.paragraph2}</p> : <></>}
          {content.linkText ? (
            <div className={styles.link}>
              <Link to={content.linkUrl}>{content.linkText}</Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <img
          loading="lazy"
          src={content.imageSrc}
          alt={content.imageAlt}
          className={
            content.imageWidth?.toLowerCase() === "overflow"
              ? styles.contentImageOverflow
              : styles.contentImageFit
          }
        />
      </div>
    </div>
  );
};

export default ImageWithContent;

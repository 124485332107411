import React from "react";
import * as styles from "./faq.module.scss";
import RightChevronIcon from "./RightChevronIcon";

const Faq = ({ faqData }) => {
  const [expanded, setExpanded] = React.useState([]);

  const handleToggle = (index) => {
    const element = document.getElementById(getPanelId(index));
    if (expanded.includes(index)) {
      element.style.height = "0px";
      element.setAttribute("aria-expanded", "false");
      setExpanded(expanded.filter((item) => item !== index));
    } else {
      element.style.height = `${element.scrollHeight}px`;
      element.setAttribute("aria-expanded", "true");
      setExpanded([...expanded, index]);
    }
  };

  return (
    <div className="container">
      <div className={styles.faqContainer}>
        {faqData.map((item, index) => {
          const isActive = expanded.includes(index);
          return (
            <div key={index} className={styles.accordionItem}>
              <div
                aria-expanded={isActive}
                aria-controls={getPanelId(index)}
                role="button"
                tabIndex="0"
                className={styles.accordionButton}
                onClick={() => handleToggle(index)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleToggle(index);
                  }
                }}
              >
                <RightChevronIcon
                  className={`${styles.icon} ${
                    isActive ? styles.rotate90 : ""
                  }`}
                />
                {item.question}
              </div>
              <div
                aria-hidden={!isActive}
                id={getPanelId(index)}
                className={styles.accordionPanel}
              >
                <p className={styles.accordionPanelContent}>{item.answer}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;

function getPanelId(index) {
  return `accordion-panel-${index}`;
}

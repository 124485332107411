import { Link } from "gatsby"
import React from "react"
import * as styles from "./getInTouch.module.scss"

const GetInTouch = ({ getInTouchData }) => {
  return (
    <div className={styles.getInTouch}>
      <div className="container">
        <h1>{getInTouchData.title}</h1>
        <Link to={getInTouchData.buttonLink}>
          <button className={styles.touchButton}>
            {getInTouchData.buttonText}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default GetInTouch

import React from "react";
import logo from "../../images/stackdx_text_white_logo.svg";
import * as styles from "./footer.module.scss";

const Footer = ({ footerData }) => {
  return (
    <div className={`container ${styles.footer}`}>
      <img src={logo} alt="StackDX logo" className={styles.logo} />
      <div className={styles.content}>
        <p>{footerData.footerLine1}</p>
        <p>
          <a href={`mailto:${footerData.mailTo}`} className={styles.action}>
            {footerData.mailTo}
          </a>{" "}
          |{" "}
          <a href={`tel:${footerData.telephone}`} className={styles.action}>
            {footerData.telephone}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;

// extracted by mini-css-extract-plugin
export var cardDark = "data-module--cardDark--3cdd7";
export var cardLight = "data-module--cardLight--9ebdc";
export var cards = "data-module--cards--82287";
export var content = "data-module--content--56ead";
export var data = "data-module--data--16d4b";
export var dataContainer = "data-module--dataContainer--9a0e2";
export var dataItem = "data-module--dataItem--99c89";
export var dataItems = "data-module--dataItems--71cff";
export var hiddenCard = "data-module--hiddenCard--9e58f";
export var link1 = "data-module--link1--0256b";
export var link2 = "data-module--link2--b91e0";
export var linkSvg = "data-module--linkSvg--1ea0d";
export var siloDatabase = "data-module--siloDatabase--4e043";
export var structuredDataContainer = "data-module--structuredDataContainer--8986d";
export var structuredImageContainer = "data-module--structuredImageContainer--91c2c";
export var unstructuredData = "data-module--unstructuredData--51c8a";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Header from "../Header";
import * as styles from "./hero.module.scss";

const Hero = ({ headerData, bannerData }) => {
  return (
    <div className={styles.hero}>
      <Header headerData={headerData} />
      <div className={styles.bannerImageContainer}>
        <StaticImage
          loading="eager"
          src="../../images/Banner_Elements.webp"
          alt=""
          className={styles.bannerImage}
        />
      </div>
      <div className={`container ${styles.parent}`}>
        <div className={styles.contentContainer}>
          <h1>{bannerData.title}</h1>
          <p>{bannerData.description}</p>
          <div className={styles.formContainer}>
            <a href={bannerData.buttonLink}>
              <button className={styles.demoButton}>
                {bannerData.buttonText}
              </button>
            </a>
            <div className={styles.loginContainer}>
              <Link to={bannerData.loginLink}>
                <button className={styles.loginButton}>
                  {bannerData.loginText}
                </button>
              </Link>
              <Link to={bannerData.otherLoginLink}>
                <span className={styles.otherLoginLink}>
                  {bannerData.otherLoginText}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

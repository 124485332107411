import { Link } from "gatsby";
import React from "react";
import linkImage1 from "../../images/Group 30.svg";
import structuredImage from "../../images/Group 31.svg";
import linkImage2 from "../../images/Group 33 (1).svg";
import * as styles from "./data.module.scss";

const Data = ({ dataSectionData }) => {
  return (
    <div className={`container ${styles.data}`}>
      <div className={styles.content}>
        <h2>{dataSectionData.title}</h2>
        <p>{dataSectionData.description}</p>
      </div>

      <div className={styles.dataContainer}>
        <div className={styles.unstructuredData}>
          <div></div>
          <div className={styles.dataItems}>
            {dataSectionData.unstructuredData.map((item, index) => {
              return (
                <div className={styles.dataItem} key={index}>
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
          <h6 aria-hidden="true">{dataSectionData.assetTitles[0]}</h6>
        </div>
        <div className={styles.siloDatabase}>
          <div></div>
          <div className={styles.dataItems}>
            <div className={styles.dataItem}></div>
            {dataSectionData.silodDatabase.map((item, index) => {
              return (
                <div className={styles.dataItem} key={index}>
                  <p>{item}</p>
                </div>
              );
            })}
            <div className={styles.dataItem}></div>
          </div>
          <h6 aria-hidden="true">{dataSectionData.assetTitles[1]}</h6>
        </div>
      </div>
      <div className={styles.linkSvg}>
        <img className={styles.link1} src={linkImage1} alt="" />
        <img className={styles.link2} src={linkImage2} alt="" />
      </div>
      <div className={styles.structuredDataContainer}>
        <div className={styles.structuredImageContainer}>
          <img src={structuredImage} alt="" />
          <h6 aria-hidden="true">{dataSectionData.assetTitles[2]}</h6>
        </div>
        <div className={styles.cards}>
          <div className={styles.cardDark}>
            <h3>{dataSectionData.card1.title}</h3>
            <p>{dataSectionData.card1.description}</p>
            <ul>
              {dataSectionData.card1.points.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
          <div className={styles.cardLight}>
            <h3>{dataSectionData.card2.title}</h3>
            <p>{dataSectionData.card2.subtitle}</p>
            <p>{dataSectionData.card2.description}</p>
            <Link to={dataSectionData.card2.buttonLink}>
              <button>{dataSectionData.card2.buttonText}</button>
            </Link>
          </div>
          <div className={styles.hiddenCard}></div>
        </div>
      </div>
    </div>
  );
};

export default Data;

import React from "react";
import logo from "../../images/stackdx_text_white_logo.svg";
import * as styles from "./header.module.scss";

const Header = () => {
  return (
    <div className={`container ${styles.header}`}>
      <img src={logo} alt="StackDX logo" className={styles.logo} />
    </div>
  );
};

export default Header;
